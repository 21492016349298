@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');
@import './variables';
@import './mixins';

// REGULAR COMPONENTS
@import '../components/regular/footer/footer';
@import '../components/regular/navbar/navbar';
@import '../components/regular/page-header/page-header';
@import '../components/regular/financial-freedom/financial-freedom';

// DASHBOARD COMPONENTS
@import '../components/dashboard/navbar/navbar';
@import '../components/dashboard/modals/modals';
@import '../components/dashboard/bottom-tab/bottom-tab';

// REGULAR PAGES
@import '../pages/regular/auth/auth';
@import '../pages/regular/auth/email-verification';
@import '../pages/regular/auth/forgot-password';
@import '../pages/regular/contact-us/contact-us';
@import '../pages/regular/loans/loans';
@import '../pages/regular/savings-investments/savings-investments';
@import '../pages/regular/security/security';
@import '../pages/regular/about-us/about-us';
@import '../pages/regular/home/home';
@import '../pages/regular/terms-conditions/terms-conditions';
@import '../pages/regular/privacy-policies/privacy-policies';
@import '../pages/regular/faqs/faqs';

// DASHBOARD PAGES
@import '../pages/dashboard/overview/overview';
@import '../pages/dashboard/notifications/notifications';
@import '../pages/dashboard/transaction-history/transaction-history';
@import '../pages/dashboard/account-settings/account-settings';
@import '../pages/dashboard/settings/settings';
@import '../pages/dashboard/deposit/deposit';
@import '../pages/dashboard/withdraw/withdraw';
@import '../pages/dashboard/loans/loans';
@import '../pages/dashboard/cards/cards';

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  max-width: 1800px;
  margin: 0 auto;
}

p {
  line-height: 28px;
  font-size: clamp(0.9rem, 3vw, 1rem);
}

a {
  color: inherit;
  text-decoration: none;
}

ul {
  list-style-type: none;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  align-self: center;
}

button {
  @include flex(center, center, row);
  background-color: transparent;
  border: none;
  border-radius: 2.8rem;
  font-weight: 700;
  padding: 0.8rem 1.5rem;
  cursor: pointer;
  font-family: 'Lato', sans-serif;
  font-size: clamp(0.9rem, 3vw, 1rem);
  letter-spacing: 0.02rem;
  transition: 0.2s linear opacity;

  img {
    margin-left: 10px;
    height: 24px;
    width: 24px;
  }

  svg {
    margin-left: 10px;
    font-size: 1.4rem;
    font-family: 600;
  }

  &.transparent {
    border: 1.5px solid $primaryColor;
    color: $primaryColor;
  }

  &.blue {
    background: linear-gradient(90deg, #0f5dd2 0%, #053987 100%);
    color: #fff;
  }

  &.white {
    background-color: #fff;
    color: #000;
  }

  &.long {
    padding: 0.8rem 2.5rem;
  }

  &:disabled {
    background: #c6c5c5 !important;
  }
}

// CUSTOM INPUT FIELDS

.form-row {
  @include for-tablet-potrait-up {
    @include flex(center, space-between, row);

    .form-control {
      width: 48%;
    }
  }
}

.form-control {
  @include flex(flex-start, center, column);
  margin-bottom: 1.5rem;

  label {
    margin-bottom: 0.5rem;
    font-size: 16px;
    font-weight: 600;

    &.error {
      color: $errorColor;
    }

    span {
      color: red;
    }
  }

  input {
    width: 100%;
    height: 100%;
    padding: 12px 16px;
    font-size: 16px;
    outline: none;
    font-family: 'Lato', sans-serif;
    background-color: #f4f4f4;
    border: none;
    border-radius: 0.3125rem;
    border-bottom: 1px solid #8e8e8e;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type='number'] {
      appearance: inherit;
      -moz-appearance: textfield;
    }
  }

  textarea {
    width: 100%;
    resize: none;
    min-height: 200px;
    margin-top: 10px;
    border-radius: 4px;
    padding: 16px;
    outline: none;
    font-family: 'Lato', sans-serif;
    border: none;
    border-bottom: 1px solid #8e8e8e;
    background-color: #f4f4f4;
  }

  select {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    padding: 16px;
    font-size: 16px;
    outline: none;
    margin-top: 5px;
    font-family: 'Lato', sans-serif;
    background-color: #f4f4f4;
    border: none;
    border-bottom: 1px solid #8e8e8e;
  }

  .password-container {
    @include flex(center, space-between, row);
    border-radius: 4px;
    margin-top: 5px;
    padding-right: 1rem;
    position: relative;
    width: 100%;
    background-color: #f4f4f4;
    border-bottom: 1px solid #8e8e8e;

    input {
      border: none;
      height: 100%;
      margin: 0;
    }

    svg {
      cursor: pointer;
      position: absolute;
      right: 8px;
    }
  }
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #f4f4f4 inset !important;
}

// RESET INPUT FONT-SIZE FOR MOBILE
@media screen and (max-width: 767px) {
  input,
  select,
  textarea {
    font-size: 16px;
  }
}

.border {
  border: 1px solid red;
}

#tidio-chat-iframe {
  inset: auto -7px 40px auto !important;
}
