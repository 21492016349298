.security-page {
  background: #f5f5f5;
  overflow-x: hidden;

  h2.title {
    text-align: center;
    font-size: clamp(1.1rem, 3vw, 1.5rem);
    margin-bottom: 1rem;
    font-weight: 600;
  }

  p.sub-title {
    line-height: 28px;
    margin-bottom: 2rem;
    text-align: center;
    color: #5e5e5e;
  }

  // SECURITY INFO
  .security-info {
    padding: 4rem 14px 2rem 14px;

    .block {
      margin-bottom: 2rem;
      h3 {
        font-size: clamp(1.1rem, 3vw, 1.3rem);
        font-weight: 700;
        margin-bottom: 0.5rem;
      }
    }
  }
}

@include for-phone-only {
  .security-page {
    // SECURITY INFO
    .security-info {
      padding: 4rem 24px 2rem 24px;
    }
  }
}

@include for-tablet-potrait-up {
  .security-page {
    // SECURITY INFO
    .security-info {
      padding: 4rem 40px 2rem 40px;
    }
  }
}

@include for-tablet-landscape-up {
  .security-page {
    // SECURITY INFO
    .security-info {
      padding: 4rem 60px 2rem 60px;
    }
  }
}

@include for-desktop-up {
  .security-page {
    p.sub-title {
      width: 80%;
      margin: 0 auto 2rem auto;
    }

    // SECURITY INFO
    .security-info {
      padding: 4rem 100px 2rem 100px;
    }
  }
}

@include for-big-desktop-up {
}
