.footer {
  padding: 3.5rem 14px;
  background-color: $darkBlue;
  color: #fff;
  margin-top: 100px;

  .grid {
    @include grid(1, 1fr, 2rem);

    .item {
      &.logo-info {
        img {
          width: 50%;
          margin-bottom: 1rem;
        }

        p {
          font-size: clamp(0.9rem, 3vw, 1rem);
          line-height: 28px;
        }
      }

      &.list {
        h3 {
          font-size: clamp(1.1rem, 3vw, 1.3rem);
          margin-bottom: 1rem;
          font-weight: 500;
        }

        ul {
          @include flex(flex-start, center, column);
          li {
            font-size: clamp(1rem, 2vw, 1.1rem);
            margin-bottom: 1rem;
            cursor: pointer;
            display: inline;

            &:hover {
              color: $primaryColor;
            }
          }
        }
      }

      &.socials {
        h3 {
          font-size: clamp(1.1rem, 3vw, 1.3rem);
          margin-bottom: 1rem;
          font-weight: 500;
        }

        ul {
          @include flex(center, flex-start, row);
          gap: 2rem;

          svg {
            font-size: 1.5rem;

            &:hover {
              color: $primaryColor;
            }
          }
        }
      }
    }
  }

  .copyright {
    @include flex(center, center, row);
    margin-top: 2rem;
    border-top: 1px solid grey;
    padding-top: 1rem;

    p {
      font-size: clamp(0.9rem, 3vw, 1rem);
      font-weight: 400;
      line-height: 26px;
      text-align: center;
    }
  }
}

@include for-phone-only {
  .footer {
    padding: 3.5rem 24px;
  }
}

@include for-tablet-potrait-up {
  .footer {
    padding: 3.5rem 40px;
    .grid {
      @include grid(2, 1fr, 3rem);
    }
  }
}

@include for-tablet-landscape-up {
  .footer {
    padding: 3.5rem 60px;

    .grid {
      .item {
        &.logo-info {
          img {
            width: 70%;
          }
        }
      }
    }
  }
}

@include for-desktop-up {
  .footer {
    padding: 3.5rem 100px;

    .grid {
      @include grid(4, 1fr, 3rem);
    }
  }
}

@include for-big-desktop-up {
}
