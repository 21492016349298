.email-verification {
  background-color: #ecefff;
  min-height: 100vh;

  .auth-navbar {
    background: #fefefe;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(20px);
    padding: 1rem 14px;
    margin-bottom: 2rem;

    .logo {
      @include flex(center, center, row);
      width: 150px;
    }

    .desktop-alternative {
      display: none;
    }
  }

  .mobile-alternative {
    @include flex(center, flex-start, row);
    margin-bottom: 2rem;
    padding: 0 14px;

    p {
      font-size: clamp(0.8rem, 2vw, 0.9rem);
      margin-right: 0.8rem;
    }
  }

  .check-email,
  .email-verified {
    @include flex(center, flex-start, column);
    text-align: center;
    padding: 1rem 14px;
    padding-bottom: 70px;

    .img {
      width: 60%;
      margin-bottom: 1.5rem;
    }

    h4 {
      margin-bottom: 1rem;
      font-size: clamp(1.1rem, 3vw, 1.5rem);
    }

    p {
      font-size: clamp(0.9rem, 3vw, 1.1rem);
    }

    .btn {
      @include flex(center, center, row);
      margin-top: 1.5rem;
    }
  }
}

@include for-phone-only {
  .email-verification {
    .auth-navbar {
      padding: 1rem 24px;
    }

    .mobile-alternative {
      padding: 0 24px;
    }

    .check-email,
    .email-verified {
      padding: 1rem 24px;
    }
  }
}

@include for-tablet-potrait-up {
  .email-verification {
    .auth-navbar {
      padding: 1rem 40px;
    }

    .mobile-alternative {
      padding: 0 40px;
    }

    .check-email,
    .email-verified {
      padding: 1rem 40px;
      margin-top: 10rem;
    }
  }
}

@include for-tablet-landscape-up {
  .email-verification {
    .auth-navbar {
      padding: 1rem 60px;
    }

    .mobile-alternative {
      padding: 0 60px;
    }

    .check-email,
    .email-verified {
      padding: 1rem 60px;
    }
  }
}

@include for-desktop-up {
  .email-verification {
    .auth-navbar {
      padding: 1rem 100px;
      margin-bottom: 3rem;
      @include flex(center, space-between, row);

      .desktop-alternative {
        @include flex(center, center, row);

        p {
          font-size: clamp(1rem, 3vw, 1.1rem);
          margin-right: 1rem;
        }
      }
    }

    .mobile-alternative {
      display: none;
    }

    .check-email,
    .email-verified {
      padding: 1rem 100px;
      margin-top: 0;

      .img {
        width: 30%;
      }

      p {
        font-size: clamp(0.9rem, 3vw, 1.1rem);
        width: 40%;
        margin: 0 auto;
      }
    }

    .email-verified {
      padding-bottom: 70px;

      p {
        width: 60%;
      }
    }
  }
}

@include for-big-desktop-up {}