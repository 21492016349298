.page-header {
  @include flex(flex-start, center, column-reverse);
  background-color: #320690;

  .text-info {
    padding: 2.25rem 14px;
    color: #fff;

    h1 {
      font-size: clamp(1.5rem, 4vw, 2.5rem);
      font-weight: 700;
      margin-bottom: 1.25rem;
    }

    p {
      font-size: clamp(0.9rem, 3vw, 1rem);
      font-weight: 400;
      line-height: 28px;
    }

    button {
      margin-top: 2rem;
    }
  }

  .img {
    @include flex(center, center, row);
    height: 100%;
  }
}

@include for-phone-only {
  .page-header {
    .text-info {
      padding: 2.25rem 24px;
    }
  }
}

@include for-tablet-potrait-up {
  .page-header {
    .text-info {
      @include flex(flex-start, center, column);
      padding: 4rem 40px;
    }
  }
}

@include for-tablet-landscape-up {
  .page-header {
    .text-info {
      padding: 4rem 60px;
    }
  }
}

@include for-desktop-up {
  .page-header {
    height: auto;
    @include flex(center, space-between, row);

    .text-info {
      padding: 6rem 100px;
      height: 100%;
      width: 55%;
    }

    .img {
      width: 45%;
    }
  }
}

@include for-big-desktop-up {
}
