.faqs-page {
  background: #f5f5f5;
  overflow-x: hidden;

  header {
    height: 30vh;
    @include flex(center, center, row);
    background-image: url('../../../assets/faqs-bg-mobile.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    h2 {
      color: #fff;
      font-size: clamp(22px, 4vw, 48px);
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }

  .content {
    padding: 3rem 14px;
    color: #000;
    max-width: 800px;

    h1 {
      font-size: clamp(1.5rem, 3vw, 1.8rem);
      font-weight: 600;
      line-height: normal;
      margin-bottom: 1.5rem;
    }

    .block {
      border-top: 1px solid #000;
      margin-bottom: 1.5rem;
      h3 {
        font-size: clamp(1.1rem, 3vw, 1.3rem);
        font-weight: 500;
        line-height: normal;
        margin: 1rem 0;
      }

      p {
        font-size: clamp(0.9rem, 3vw, 1rem);
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 0.8px;
        margin-bottom: 1.2rem;
        opacity: 0.8;
      }
    }
  }
}

@include for-phone-only {
  .faqs-page {
    .content {
      padding: 3rem 24px;
    }
  }
}

@include for-tablet-potrait-up {
  .faqs-page {
    header {
      height: 40vh;
      @include flex(center, flex-start, row);
      padding-left: 40px;
    }

    .content {
      padding: 3rem 40px;
    }
  }
}

@include for-tablet-landscape-up {
  .faqs-page {
    header {
      padding-left: 60px;
      background-image: url('../../../assets/faqs-bg.png');
    }

    .content {
      padding: 3rem 60px;
    }
  }
}

@include for-desktop-up {
  .faqs-page {
    header {
      height: 400px;
      padding-left: 100px;
    }

    .content {
      padding: 3rem 100px;
    }
  }
}

@include for-big-desktop-up {
}
