.regular-navbar {
  @include flex(center, space-between, row);
  padding: 1rem 14px;
  background: #fefefe;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(20px);
  position: relative;
  z-index: 40;

  .logo {
    @include flex(center, center, row);
    width: 150px;
  }

  .hamburger {
    @include flex(center, center, row);
    width: 1rem;
    height: 0.875rem;
  }

  .links {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    @include flex(center, space-between, row);
    transform: translateX(100%);
    transition: 0.4s linear all;
    z-index: 100;

    &.show {
      z-index: 100;
      transform: translateX(0);
      transition: 0.4s linear all;
    }

    .void {
      width: 30%;
      height: 100%;
      background: rgba(0, 0, 0, 0.4);
    }

    .cont {
      width: 70%;
      height: 100%;
      background-color: #fff;
      @include flex(flex-start, flex-start, column);
      padding: 2rem 14px;
      position: relative;

      ul {
        margin-bottom: 2rem;
        @include flex(flex-start, center, column);
        position: relative;
        li {
          margin: 0 0 1.5rem 0;
          font-size: 1rem;
          font-weight: 500;
          cursor: pointer;
          display: inline;
          &:hover {
            color: $primaryColor;
          }
        }
      }

      .btns {
        width: 100%;
        button {
          width: 100%;
          margin-bottom: 1.5rem;
        }
      }
    }
  }
}

@include for-phone-only {
  .regular-navbar {
    padding: 1rem 24px;

    .links {
      .cont {
        padding: 3rem 24px;
      }
    }
  }
}

@include for-tablet-potrait-up {
  .regular-navbar {
    padding: 1rem 40px;

    .links {
      .cont {
        padding: 2rem 40px;
        .btns {
          @include flex(center, space-between, row);
          button {
            width: 48%;
          }
        }
      }
    }
  }
}

@include for-tablet-landscape-up {
  .regular-navbar {
    padding: 1rem 60px;

    .links {
      .cont {
        padding: 2rem 60px;
      }
    }
  }
}

@include for-desktop-up {
  .regular-navbar {
    @include flex(center, space-between, row);
    padding: 1rem 100px;

    .hamburger {
      display: none;
    }

    .links {
      @include flex(center, flex-start, row);
      position: static;
      width: auto;
      height: auto;
      transform: translateX(0);

      .void {
        display: none;
      }
      .cont {
        @include flex(center, flex-start, row);
        padding: 0;
        width: auto;

        ul {
          @include flex(center, flex-start, row);
          margin-bottom: 0;

          li {
            margin: 0 2rem 0 0;
            font-size: clamp(1rem, 3vw, 1rem);
            font-weight: 500;
            cursor: pointer;
            &:hover {
              color: $primaryColor;
            }
          }
        }

        .btns {
          @include flex(center, flex-start, row);
          width: auto;
          button {
            margin-left: 2rem;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

@include for-big-desktop-up {
}
