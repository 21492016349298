.transaction-history-page {
  background-color: #f5f5f5;
  min-height: 100vh;
  padding-bottom: 100px;

  .page-title {
    display: none;
  }

  // tabs
  .tabs {
    overflow-x: scroll;
    margin-top: 2.5rem;
    padding: 0 14px;
    scrollbar-width: none;
    margin-bottom: 2.5rem;

    &::-webkit-scrollbar {
      display: none;
    }

    ul {
      @include flex(center, center, row);
      width: 400px;
      padding-bottom: 1rem;

      li {
        padding: 0.5rem 0.8rem;
        margin-right: 0.5rem;
        font-size: clamp(0.9rem, 2vw, 1rem);
        color: #808080;
        cursor: pointer;

        &.current-tab {
          border-bottom: 2px solid $primaryColor;
          color: $primaryColor;
        }
      }
    }
  }

  // transaction card
  .card-container {
    padding: 0 14px;
    margin-bottom: 100px;

    .card-loading {
      @include flex(center, center, row);
      height: 40vh;

      img {
        width: 2rem;
        height: 2rem;
      }
    }

    .transaction-card {
      border-radius: 1.25rem;
      background: #f8f9ff;
      box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
      width: 100%;
      padding: 1.5rem;

      .title {
        margin-bottom: 1rem;

        h3 {
          font-size: clamp(0.9rem, 3vw, 1rem);
        }
      }

      .no-transaction {
        @include flex(center, center, row);
        height: 20vh;

        h3 {
          font-size: clamp(1rem, 3vw, 1.3rem);
          font-weight: 500;
        }
      }

      .table {
        overflow-x: scroll;

        .headers {
          margin-bottom: 1.5rem;

          li {
            font-size: clamp(0.9rem, 3vw, 1rem);
          }
        }

        .transaction {
          @include flex(center, space-between, row);
          margin-bottom: 1rem;

          .item {
            @include flex(center, flex-start, row);

            img {
              width: 1.25rem;
              height: 1.25rem;
              margin-right: 0.2rem;
            }

            p {
              font-size: clamp(0.8rem, 2vw, 1rem);

              &.via,
              &.status {
                text-transform: capitalize;
              }

              &.via {
                text-align: start;
              }

              &.status {
                border-radius: 10px;
                padding: 0.3rem 1rem;
              }

              &.pending {
                color: #ffd400;
                background-color: #fffbe6;
              }

              &.approved {
                color: #78bf23;
                background-color: #f2f9e9;
              }

              &.failed {
                color: #e35640;
                background-color: #fceae7;
              }
            }
          }
        }
      }

      // DEPOSITS
      &.deposits {
        .table {
          .headers {
            @include grid(5, 130px 70px 100px 70px 100px, 10px);
          }

          .transaction {
            @include grid(5, 130px 70px 100px 70px 100px, 10px);
          }
        }
      }

      // LOANS
      &.loans {
        .table {
          .headers {
            @include grid(6, 130px 70px 100px 160px 100px 80px, 10px);
          }

          .transaction {
            @include grid(6, 130px 70px 100px 160px 100px 80px, 10px);
          }
        }
      }
    }
  }
}

@include for-phone-only {
  .transaction-history-page {
    .tabs {
      padding: 0 24px;
    }

    // transaction card
    .card-container {
      padding: 0 24px;
    }
  }
}

@include for-tablet-potrait-up {
  .transaction-history-page {
    .tabs {
      padding: 0 40px;
    }

    // transaction card
    .card-container {
      padding: 0 40px;
      @include flex(center, center, row);

      .transaction-card {
        width: 100%;

        .title {
          margin-bottom: 1rem;

          h3 {
            font-size: clamp(1rem, 3vw, 1.5rem);
          }
        }

        .table {
          .headers {
            li {
              text-align: center;
            }
          }

          .transaction {
            .item {
              text-align: center;

              img {
                width: 1.5rem;
                height: 1.5rem;
                margin-right: 0.5rem;
              }

              p {
                text-align: center;
                width: 100%;
              }
            }
          }
        }

        // DEPOSITS
        &.deposits {
          .table {
            .headers {
              @include grid(5, 150px 70px 230px 100px 100px, 10px);
            }

            .transaction {
              @include grid(5, 150px 70px 230px 100px 100px, 10px);
            }
          }
        }

        // LOANS
        &.loans {
          .table {
            .headers {
              @include grid(6, 180px 70px 100px 230px 120px 80px, 10px);
            }

            .transaction {
              @include grid(6, 180px 70px 100px 230px 120px 80px, 10px);
            }
          }
        }
      }
    }
  }
}

@include for-tablet-landscape-up {
  .transaction-history-page {
    .tabs {
      padding: 0 60px;
    }

    .card-container {
      padding: 0 60px;
    }
  }
}

@include for-desktop-up {
  .transaction-history-page {
    padding-bottom: 100px;

    .page-title {
      display: block;
      margin: 2.5rem 0 2rem 0;
      padding: 0 100px;

      h3 {
        font-size: clamp(1.3rem, 4vw, 1.5rem);
      }
    }

    .tabs {
      padding: 0 100px;

      ul {
        @include flex(center, space-between, row);
        width: 60%;
        margin: 0 auto;
      }
    }

    // transaction card
    .card-container {
      padding: 0 100px;

      .transaction-card {
        width: 100%;

        .table {
          overflow-x: hidden;
        }

        // DEPOSITS
        &.deposits {
          .table {
            .headers {
              @include grid(5, 1fr, 10px);
            }

            .transaction {
              @include grid(5, 1fr, 10px);
            }
          }
        }

        // LOANS
        &.loans {
          .table {
            .headers {
              @include grid(6, 1fr, 10px);
            }

            .transaction {
              @include grid(6, 1fr, 10px);
            }
          }
        }
      }
    }
  }
}

@include for-big-desktop-up {
}
