.terms-conditions-page {
  background: #f5f5f5;
  overflow-x: hidden;

  .content {
    padding: 3rem 14px;
    color: #000;

    h1 {
      font-size: clamp(1.5rem, 3vw, 1.8rem);
      font-weight: 600;
      line-height: normal;
      margin-bottom: 1.5rem;
    }

    h3 {
      font-size: clamp(1.1rem, 3vw, 1.3rem);
      font-weight: 500;
      line-height: normal;
      margin: 1.5rem 0;
    }

    p {
      font-size: clamp(0.9rem, 3vw, 1rem);
      font-weight: 400;
      line-height: 28px;
      letter-spacing: 0.8px;
      margin-bottom: 1.2rem;
      opacity: 0.8;
    }

    ul {
      padding-left: 14px;
      list-style-type: disc;

      li {
        font-size: clamp(0.9rem, 3vw, 1rem);
        margin-bottom: 0.5rem;
        font-weight: 300;
        line-height: 24px;
      }
    }
  }
}

@include for-phone-only {
  .terms-conditions-page {
    .content {
      padding: 3rem 24px;
    }
  }
}

@include for-tablet-potrait-up {
  .terms-conditions-page {
    .content {
      padding: 3rem 40px;
    }
  }
}

@include for-tablet-landscape-up {
  .terms-conditions-page {
    .content {
      padding: 3rem 60px;
    }
  }
}

@include for-desktop-up {
  .terms-conditions-page {
    .content {
      padding: 3rem 100px;
    }
  }
}

@include for-big-desktop-up {
}
