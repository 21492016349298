.notifications-page {
  background-color: #f5f5f5;
  min-height: 100vh;
  padding-bottom: 100px;

  .page-title {
    padding: 1.5rem 14px 1rem 14px;

    h3 {
      font-size: clamp(1rem, 3vw, 1.5rem);
      font-weight: 600;
      text-transform: capitalize;
    }
  }

  .loader {
    @include flex(center, center, row);
    height: 30vh;

    img {
      width: 32px;
      height: 32px;
    }
  }

  .no-notifications {
    @include flex(center, center, row);
    padding: 3rem 0;

    h1 {
      font-size: clamp(1.1rem, 3vw, 1.5rem);
    }
  }

  .cards-container {
    @include flex(flex-start, flex-start, column);
    margin-top: 0.5rem;
    padding: 0 14px;

    .notif-card {
      padding: 1.25rem;
      border-radius: 1.25rem;
      background: #fff;
      position: relative;
      margin-bottom: 1.5rem;
      width: 100%;

      .title-time {
        @include flex(flex-start, flex-start, column-reverse);
        margin-bottom: 0.75rem;

        h4 {
          font-size: clamp(1rem, 3vw, 1.1rem);
        }

        h5 {
          @include flex(center, flex-start, row);
          margin-bottom: 0.3rem;

          span {
            font-size: clamp(0.8rem, 2vw, 0.9rem);
            color: #808080;
            margin-right: 0.5rem;
          }
        }
      }

      p {
        font-size: clamp(0.9rem, 3vw, 1.1rem);
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        max-width: 500px;
      }

      .btns {
        position: absolute;
        right: 1.25rem;
        top: 1.25rem;
        @include flex(center, center, row);

        svg.mail {
          margin-right: 1rem;
          font-size: 1.5rem;
          color: $primaryColor;
          cursor: pointer;
        }

        .delete {
          @include flex(center, center, row);
          width: 1.3125rem;
          height: 1.3125rem;
          cursor: pointer;
        }
      }
    }
  }
}

@include for-phone-only {
  .notifications-page {
    .page-title {
      padding: 1.5rem 24px 1rem 24px;
    }

    .cards-container {
      padding: 0 24px;
    }
  }
}

@include for-tablet-potrait-up {
  .notifications-page {
    .page-title {
      padding: 1.5rem 40px 1rem 40px;
    }

    .cards-container {
      padding: 0 40px;
    }
  }
}

@include for-tablet-landscape-up {
  .notifications-page {
    .page-title {
      padding: 1.5rem 60px 1rem 60px;
    }

    .cards-container {
      padding: 0 60px;
    }
  }
}

@include for-desktop-up {
  .notifications-page {
    .page-title {
      padding: 2rem 100px;
    }

    .cards-container {
      @include flex(flex-start, flex-start, column);
      padding: 0 100px;

      .notif-card {
        width: 100%;

        .title-time {
          @include flex(center, flex-start, row);

          h4 {
            margin-right: 1rem;
          }

          h5 {
            margin-bottom: 0;
          }
        }

        .btns {
          top: 40%;
        }
      }
    }
  }
}

@include for-big-desktop-up {
}
