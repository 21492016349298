.auth-page {
  background: #ecefff;
  padding-bottom: 5rem;
  min-height: 100vh;

  .auth-navbar {
    background: #fefefe;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(20px);
    padding: 1rem 14px;
    margin-bottom: 2rem;

    .logo {
      @include flex(center, center, row);
      width: 150px;
    }

    .desktop-alternative {
      display: none;
    }
  }

  .form-container {
    padding: 0 14px;

    form {
      .mobile-alternative {
        @include flex(center, flex-start, row);
        margin-bottom: 2rem;

        p {
          font-size: clamp(0.8rem, 2vw, 0.9rem);
          margin-right: 0.8rem;
        }
      }

      .header {
        margin-bottom: 2rem;

        h4 {
          font-size: clamp(0.8rem, 2vw, 0.9rem);
          margin-bottom: 0.5rem;
          color: #808080;
          font-weight: 400;
        }

        h3 {
          font-size: clamp(1.4rem, 3vw, 1.8rem);
        }
      }

      .form-control {
        .country-select {
          width: 100%;

          .css-13cymwt-control {
            padding: 4px 16px;
            background-color: #f4f4f4;
            font-family: 'Lato', sans-serif;
            border: none;
            border-radius: 0.3125rem;
            border-bottom: 1px solid #8e8e8e;
            font-size: 16px;
          }
        }

        .phone-input {
          .input {
            width: 100%;
            font-family: 'Lato', sans-serif;
            background-color: #f4f4f4;
            padding-top: 1.5rem;
            padding-bottom: 1.5rem;
            border: none;
            border-radius: 0.3125rem;
            border-bottom: 1px solid #8e8e8e;
            font-size: 16px;
          }

          .btn {
            border: none;
            border-bottom: 1px solid #8e8e8e;
          }
        }

        select#account {
          height: 58px;
        }
      }

      .terms-and-conditions {
        font-size: 0.9rem;
        line-height: 26px;
        margin-bottom: 2rem;

        a {
          color: $primaryColor;
        }
      }

      .forgot-password {
        margin-bottom: 2rem;
        @include flex(center, flex-end, row);
      }

      .auth-error {
        @include flex(center, flex-start, row);
        margin-bottom: 1rem;

        p {
          font-size: clamp(0.8rem, 2vw, 0.9rem);
          line-height: 26px;
          color: $errorColor;
        }
      }
    }

    .img {
      display: none;
    }
  }
}

@include for-phone-only {
  .auth-page {
    .auth-navbar {
      padding: 1rem 24px;
    }

    .form-container {
      padding: 0 24px;
    }
  }
}

@include for-tablet-potrait-up {
  .auth-page {
    .auth-navbar {
      padding: 1rem 40px;
    }

    .form-container {
      padding: 0 40px;
    }
  }
}

@include for-tablet-landscape-up {
  .auth-page {
    .auth-navbar {
      padding: 1rem 60px;
    }

    .form-container {
      padding: 0 60px;
    }
  }
}

@include for-desktop-up {
  .auth-page {
    .auth-navbar {
      padding: 1rem 100px;
      margin-bottom: 5rem;
      @include flex(center, space-between, row);

      .desktop-alternative {
        @include flex(center, center, row);

        p {
          font-size: clamp(1rem, 3vw, 1.1rem);
          margin-right: 1rem;
        }
      }
    }

    .form-container {
      @include flex(flex-start, space-between, row);

      form {
        width: 40%;

        .mobile-alternative {
          display: none;
        }
      }

      .img {
        @include flex(center, center, row);
        width: 40%;

        img {
          width: 100%;
        }
      }
    }

    &.register-page {
      .form-container {
        position: relative;

        .img {
          position: absolute;
          right: 0;
        }
      }
    }
  }
}

@include for-big-desktop-up {
}
