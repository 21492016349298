.withdraw-page {
  background-color: #f5f5f5;
  min-height: 100vh;
  padding-bottom: 100px;

  .page-title {
    padding: 1.5rem 14px 1rem 14px;
    @include flex(center, flex-start, row);

    svg {
      margin-right: 0.5rem;
      cursor: pointer;

      &:hover {
        color: $primaryColor;
      }
    }

    h3 {
      font-size: clamp(1rem, 3vw, 1.5rem);
      font-weight: 600;
      text-transform: capitalize;
    }
  }

  .methods {
    margin-top: 1.5rem;
    padding: 0 14px;
    @include flex(flex-start, center, column);

    .card {
      border-radius: 1rem;
      background: #fff;
      padding: 1rem 1.25rem;
      margin-bottom: 1.25rem;
      width: 100%;

      h3 {
        font-size: clamp(1rem, 3vw, 1.1rem);
        font-weight: 500;
      }
    }
  }
}

// CRYPTO, PAYAL && SKRILL WITHDRAWAL
.withdraw-page.crypto,
.withdraw-page.paypal,
.withdraw-page.skrill {
  padding-bottom: 100px;

  .withdrawal-form {
    margin-top: 2rem;
    padding: 0 14px;

    form {
      background-color: #fff;
      padding: 1.5rem 14px;
      border-radius: 1.2rem;

      .error-msg {
        margin-bottom: 1rem;
        color: $errorColor;
      }
    }
  }

  .upload-btn {
    @include flex(center, center, row);
  }
}

// BANK WITHDRAWAL
.withdraw-page.bank {
  padding-bottom: 100px;

  .withdrawal-form {
    margin-top: 2rem;
    padding: 0 14px;

    form {
      background-color: #fff;
      padding: 1.5rem 14px;
      border-radius: 1.2rem;

      select {
        height: 58px;
        color: #000;
      }
    }
  }

  .upload-btn {
    @include flex(center, center, row);
  }
}

@include for-phone-only {
  .withdraw-page {
    .page-title {
      padding: 1.5rem 24px 1rem 24px;
    }

    .methods {
      padding: 0 24px;
    }
  }

  // CRYPTO, PAYAL && SKRILL WITHDRAWAL
  .withdraw-page.crypto,
  .withdraw-page.paypal,
  .withdraw-page.skrill {
    .withdrawal-form {
      padding: 0 24px;
    }
  }
}

@include for-tablet-potrait-up {
  .withdraw-page {
    .page-title {
      padding: 1.5rem 40px 1rem 40px;
    }

    .methods {
      padding: 0 40px;
    }
  }

  // CRYPTO, PAYAL && SKRILL WITHDRAWAL
  .withdraw-page.crypto,
  .withdraw-page.paypal,
  .withdraw-page.skrill {
    .withdrawal-form {
      padding: 0 40px;

      form {
        padding: 1.5rem 24px;
      }
    }
  }
}

@include for-tablet-landscape-up {
  .withdraw-page {
    .page-title {
      padding: 1.5rem 60px 1rem 60px;
    }

    .methods {
      padding: 0 60px;
    }
  }

  // CRYPTO, PAYAL && SKRILL WITHDRAWAL
  .withdraw-page.crypto,
  .withdraw-page.paypal,
  .withdraw-page.skrill {
    .withdrawal-form {
      padding: 0 60px;
    }
  }
}

@include for-desktop-up {
  .withdraw-page {
    padding-bottom: 100px;

    .page-title {
      padding: 2rem 100px;
    }

    .methods {
      padding: 0 100px;
    }
  }

  // CRYPTO, PAYAL && SKRILL WITHDRAWAL
  .withdraw-page.crypto,
  .withdraw-page.paypal,
  .withdraw-page.skrill {
    .withdrawal-form {
      padding: 0 100px;

      form {
        .form-control {
          width: 50%;
        }
      }
    }
  }

  // BANK WITHDRAWAL
  .withdraw-page.bank {
    .withdrawal-form {
      padding: 0 100px;

      form {
        .form-control {
          width: 50%;
        }

        .form-row {
          .form-control {
            width: 48%;
          }
        }
      }
    }
  }
}

@include for-big-desktop-up {
}
