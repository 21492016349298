.financial-freedom {
  background-color: $darkBlue;
  color: #fff;
  margin: 0 14px;
  border-radius: 0.625rem;

  .text {
    padding: 2rem 14px;

    h2 {
      font-size: clamp(1.5rem, 4vw, 1.6rem);
      font-weight: 700;
      margin-bottom: 1.25rem;
    }

    p {
      font-size: clamp(0.9rem, 3vw, 1rem);
      line-height: 28px;
      margin-bottom: 2.5rem;
      font-weight: 400;
    }
  }

  .img {
    display: none;
  }
}

@include for-phone-only {
  .financial-freedom {
    margin: 0 24px;

    .text {
      padding: 2rem 14px;
    }
  }
}

@include for-tablet-potrait-up {
  .financial-freedom {
    margin: 0 40px;

    .text {
      padding: 2rem 24px;
    }
  }
}

@include for-tablet-landscape-up {
  .financial-freedom {
    margin: 0 60px;
  }
}

@include for-desktop-up {
  .financial-freedom {
    margin: 0 100px;
    @include flex(center, space-between, row);

    .text {
      width: 60%;
      padding: 2rem 4rem;
    }

    .img {
      @include flex(center, center, row);
      width: 50%;
      background-color: #fff;
      height: 100%;
    }
  }
}

@include for-big-desktop-up {
}
