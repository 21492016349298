.loans-page {
  background-color: #f5f5f5;
  min-height: 100vh;
  padding-bottom: 100px;

  .page-title {
    padding: 1.5rem 14px 1rem 14px;
    @include flex(center, flex-start, row);

    svg {
      margin-right: 0.5rem;
      cursor: pointer;

      &:hover {
        color: $primaryColor;
      }
    }

    h3 {
      font-size: clamp(1rem, 3vw, 1.5rem);
      font-weight: 600;
      text-transform: capitalize;
    }
  }

  .options {
    margin-top: 1.5rem;
    padding: 0 14px;
    @include flex(flex-start, center, column);

    .card {
      border-radius: 1rem;
      background: #fff;
      padding: 1rem 1.25rem;
      margin-bottom: 1.25rem;
      width: 100%;

      h3 {
        font-size: clamp(1rem, 3vw, 1.1rem);
        font-weight: 500;

        &:hover {
          color: $primaryColor;
        }
      }
    }
  }
}

// REQUEST LOAN && CHECK ELIGIBILITY
.loans-page.request-loan,
.loans-page.check-eligibility {
  padding-bottom: 100px;

  .loan-form {
    margin-top: 2rem;
    padding: 0 14px;

    form {
      background-color: #fff;
      padding: 1.5rem 14px;
      border-radius: 1.2rem;
    }
  }
}

// CONTACT SUPPORT
.contact-support {
  @include flex(center, center, column);
  background-color: #fff;
  padding: 14px;
  width: 90%;
  max-width: 600px;
  margin: 2rem auto 0 auto;
  border-radius: 5px;

  .img {
    height: 100%;
    width: 100%;
  }

  h1 {
    font-size: clamp(1.1rem, 3vw, 1.4rem);
    font-weight: 500;
    text-align: center;
    margin-bottom: 1rem;
    line-height: 28px;
  }
}

@include for-phone-only {
  .loans-page {
    .page-title {
      padding: 1.5rem 24px 1rem 24px;
    }

    .options {
      padding: 0 24px;
    }
  }

  // REQUEST LOAN && CHECK ELIGIBILITY
  .loans-page.request-loan,
  .loans-page.check-eligibility {
    .loan-form {
      padding: 0 24px;
    }
  }
}

@include for-tablet-potrait-up {
  .loans-page {
    .page-title {
      padding: 1.5rem 40px 1rem 40px;
    }

    .options {
      padding: 0 40px;
    }
  }

  // REQUEST LOAN && CHECK ELIGIBILITY
  .loans-page.request-loan,
  .loans-page.check-eligibility {
    .loan-form {
      padding: 0 40px;

      form {
        padding: 1.5rem 24px;
      }
    }
  }
}

@include for-tablet-landscape-up {
  .loans-page {
    .page-title {
      padding: 1.5rem 60px 1rem 60px;
    }

    .options {
      padding: 0 60px;
    }
  }

  // REQUEST LOAN && CHECK ELIGIBILITY
  .loans-page.request-loan,
  .loans-page.check-eligibility {
    .loan-form {
      padding: 0 60px;
    }
  }
}

@include for-desktop-up {
  .loans-page {
    padding-bottom: 100px;

    .page-title {
      padding: 2rem 100px;
    }

    .options {
      padding: 0 100px;
    }
  }

  // REQUEST LOAN && CHECK ELIGIBILITY
  .loans-page.request-loan,
  .loans-page.check-eligibility {
    .loan-form {
      padding: 0 100px;

      form {
        .form-control {
          width: 50%;
        }
      }
    }
  }
}

@include for-big-desktop-up {
}
