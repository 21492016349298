.about-us-page {
  background: #f5f5f5;
  overflow-x: hidden;

  h2.title {
    text-align: center;
    font-size: clamp(1.2rem, 3vw, 1.5rem);
    margin-bottom: 1rem;
    font-weight: 600;
  }

  p.sub-title {
    line-height: 28px;
    margin-bottom: 2rem;
    text-align: center;
    color: #5e5e5e;
  }

  // OUR VALUES
  .our-values {
    padding: 4rem 14px 2rem 14px;

    .values {
      @include flex(flex-start, center, column-reverse);

      img {
        @include flex(center, center, row);
      }

      .info {
        border-radius: 0rem 0rem 0.625rem 0.625rem;
        background: #ecefff;
        padding: 1.25rem 14px;

        h3 {
          margin-bottom: 0.5rem;
        }

        .btns {
          margin-top: 1.5rem;
          @include flex(center, space-between, row);

          img {
            width: 2.375rem;
            height: 2.375rem;
          }
        }
      }
    }
  }

  // OUR MISSION
  .our-mission {
    padding: 4rem 14px 2rem 14px;
  }

  // OUR TEAM
  .our-team {
    padding: 4rem 14px 2rem 14px;

    .info-img {
      @include flex(flex-start, center, column-reverse);

      .info {
        p {
          line-height: 30px;
        }
      }

      .img {
        margin-bottom: 1.5rem;
      }
    }
  }

  // OUR GOALS
  .our-goals {
    padding: 4rem 14px 2rem 14px;

    .cards {
      @include grid(1, 1fr, 1.5rem);

      .card {
        border-radius: 1.25rem;
        background: #ecefff;
        padding: 1.8rem 14px;
        text-align: center;
        margin-bottom: 2.5rem;

        h3 {
          margin-bottom: 0.5rem;
        }

        p {
          color: #5e5e5e;
          line-height: 30px;
        }
      }
    }
  }
}

@include for-phone-only {
  .about-us-page {
    // OUR VALUES
    .our-values {
      padding: 4rem 24px 2rem 24px;
    }

    // OUR MISSION
    .our-mission {
      padding: 4rem 24px 2rem 24px;
    }

    // OUR TEAM
    .our-team {
      padding: 4rem 24px 2rem 24px;
    }

    // OUR GOALS
    .our-goals {
      padding: 4rem 24px 2rem 24px;
    }
  }
}

@include for-tablet-potrait-up {
  .about-us-page {
    // OUR VALUES
    .our-values {
      padding: 4rem 40px 2rem 40px;

      .values {
        .info {
          padding: 1.25rem 24px;

          .btns {
            width: 30%;
          }
        }
      }
    }

    // OUR MISSION
    .our-mission {
      padding: 4rem 40px 2rem 40px;
    }

    // OUR TEAM
    .our-team {
      padding: 4rem 40px 2rem 40px;
    }

    // OUR GOALS
    .our-goals {
      padding: 4rem 40px 2rem 40px;

      .cards {
        @include grid(2, 1fr, 1.5rem);
      }
    }
  }
}

@include for-tablet-landscape-up {
  .about-us-page {
    // OUR VALUES
    .our-values {
      padding: 4rem 60px 2rem 60px;

      .values {
        @include flex(center, center, row);
        height: 29.8125rem;

        .img {
          width: 26.9375rem;
          height: 100%;
        }

        .info {
          padding: 4.25rem 40px;
          width: 60%;
          height: 100%;
          @include flex(flex-start, center, column);
        }
      }
    }

    // OUR MISSION
    .our-mission {
      padding: 4rem 60px 2rem 60px;
    }

    // OUR TEAM
    .our-team {
      padding: 4rem 60px 2rem 60px;

      .info-img {
        margin-top: 2rem;
        @include flex(center, space-between, row);

        .info {
          width: 50%;
          margin-right: 2rem;
        }

        .img {
          margin-bottom: 0;
          width: 50%;
        }
      }
    }

    // OUR GOALS
    .our-goals {
      padding: 4rem 60px 2rem 60px;
    }
  }
}

@include for-desktop-up {
  .about-us-page {
    p.sub-title {
      width: 80%;
      margin: 0 auto 2rem auto;
    }

    // OUR VALUES
    .our-values {
      padding: 4rem 100px 2rem 100px;

      .values {
        .img {
          width: 40%;
        }

        .info {
          width: 60%;
        }
      }
    }

    // OUR MISSION
    .our-mission {
      padding: 4rem 100px 2rem 100px;
    }

    // OUR TEAM
    .our-team {
      padding: 4rem 100px 2rem 100px;
    }
    // OUR GOALS
    .our-goals {
      padding: 4rem 100px 2rem 100px;

      .cards {
        @include grid(2, 1fr, 1.5rem);
      }
    }
  }
}

@include for-big-desktop-up {
}
