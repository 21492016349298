.contact-us-page {
  background: #f5f5f5;
  overflow-x: hidden;

  // GET IN TOUCH
  .get-in-touch {
    padding: 4rem 0;
    margin-bottom: 1rem;
    .title-info {
      @include flex(center, center, column);
      text-align: center;
      margin-bottom: 2.5rem;
      padding: 0 14px;

      h3 {
        font-size: clamp(1.4rem, 3vw, 1.8rem);
        margin-bottom: 1rem;
        font-weight: 700;
      }

      p {
        color: #5e5e5e;
        font-size: clamp(0.9rem, 3vw, 1rem);
        line-height: 26px;
        font-weight: 400;
      }
    }

    form {
      background-color: #fff;
      border-radius: 0.625rem;
      padding: 2rem 14px;

      .btn {
        @include flex(center, center, row);
      }
    }
  }
}

@include for-phone-only {
  .contact-us-page {
    // GET IN TOUCH
    .get-in-touch {
      .title-info {
        padding: 0 24px;
      }

      form {
        padding: 2rem 24px;
      }
    }
  }
}

@include for-tablet-potrait-up {
  .contact-us-page {
    // GET IN TOUCH
    .get-in-touch {
      .title-info {
        padding: 0 40px;
      }

      form {
        padding: 2rem 40px;
      }
    }
  }
}

@include for-tablet-landscape-up {
  .contact-us-page {
    // GET IN TOUCH
    .get-in-touch {
      .title-info {
        padding: 0 60px;
      }

      form {
        padding: 2rem 60px;
      }
    }
  }
}

@include for-desktop-up {
  .contact-us-page {
    // GET IN TOUCH
    .get-in-touch {
      .title-info {
        padding: 0 100px;
        width: 70%;
        margin: 0 auto 5rem auto;
      }

      form {
        padding: 2rem 2.5rem;
        margin: 0 100px;
      }
    }
  }
}

@include for-big-desktop-up {
}
