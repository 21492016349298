.deposit-page {
  background-color: #f5f5f5;
  min-height: 100vh;
  padding-bottom: 100px;

  .page-title {
    padding: 1.5rem 14px 1rem 14px;
    @include flex(center, flex-start, row);

    svg {
      margin-right: 0.5rem;
      cursor: pointer;

      &:hover {
        color: $primaryColor;
      }
    }

    h3 {
      font-size: clamp(1rem, 3vw, 1.5rem);
      font-weight: 600;
      text-transform: capitalize;
    }
  }

  .methods {
    margin-top: 0.5rem;
    padding: 0 14px;
    @include flex(flex-start, center, column);

    .card {
      border-radius: 1rem;
      background: #fff;
      padding: 1rem 1.25rem;
      margin-bottom: 1.25rem;
      width: 100%;

      h3 {
        font-size: clamp(1rem, 3vw, 1.1rem);
        font-weight: 500;
      }
    }
  }
}

// CRYPTO DEPOSIT
.deposit-page.crypto {
  padding-bottom: 100px;

  .wallets {
    margin-top: 2.5rem;
    padding: 0 14px;
    margin-bottom: 2rem;

    .wallet {
      border-radius: 1.25rem;
      background: #fff;
      padding: 1rem;
      margin-bottom: 2rem;

      h3 {
        font-size: clamp(1.2rem, 3vw, 1.3rem);
        margin-bottom: 1rem;
        text-align: center;
        font-weight: 500;
      }

      .address {
        margin-bottom: 1rem;
        @include flex(center, center, row);

        p {
          font-size: clamp(0.8rem, 2vw, 0.9rem);
          color: #357eeb;
          text-align: center;
          overflow-x: scroll;
          padding-bottom: 1rem;
          scrollbar-width: none;

          &::-webkit-scrollbar {
            display: none;
          }
        }

        svg {
          font-size: 1.2rem;
          color: #8d8a8a;
        }
      }

      .btn {
        @include flex(center, center, row);
      }
    }
  }

  .upload-btn {
    @include flex(center, center, row);
  }
}

// CONTACT SUPPORT
.contact-support {
  @include flex(center, center, column);
  background-color: #fff;
  padding: 14px;
  width: 90%;
  max-width: 600px;
  margin: 2rem auto 0 auto;
  border-radius: 5px;

  .img {
    height: 100%;
    width: 100%;
  }

  h1 {
    font-size: clamp(1.1rem, 3vw, 1.4rem);
    font-weight: 500;
    text-align: center;
    margin-bottom: 1rem;
    line-height: 28px;
  }
}

@include for-phone-only {
  .deposit-page {
    .page-title {
      padding: 1.5rem 24px 1rem 24px;
    }

    .methods {
      padding: 0 24px;
    }
  }

  // CRYPTO DEPOSIT
  .deposit-page.crypto {
    .wallets {
      padding: 0 24px;
    }
  }
}

@include for-tablet-potrait-up {
  .deposit-page {
    .page-title {
      padding: 1.5rem 40px 1rem 40px;
    }

    .methods {
      padding: 0 40px;
    }
  }

  // CRYPTO DEPOSIT
  .deposit-page.crypto {
    .wallets {
      padding: 0 40px;
    }
  }
}

@include for-tablet-landscape-up {
  .deposit-page {
    .page-title {
      padding: 1.5rem 60px 1rem 60px;
    }

    .methods {
      padding: 0 60px;
    }
  }

  // CRYPTO DEPOSIT
  .deposit-page.crypto {
    .wallets {
      padding: 0 60px;
    }
  }
}

@include for-desktop-up {
  .deposit-page {
    padding-bottom: 100px;

    .page-title {
      padding: 2rem 100px;
    }

    .methods {
      padding: 0 100px;
    }
  }

  // CRYPTO DEPOSIT
  .deposit-page.crypto {
    .wallets {
      padding: 0 100px;
      @include grid(3, 1fr, 20px);

      .wallet {
        padding: 1.5rem;

        h3 {
          text-align: start;
        }

        .address {
          @include flex(center, flex-start, row);
        }

        .btn {
          @include flex(center, flex-start, row);
        }
      }
    }
  }
}

@include for-big-desktop-up {
}
