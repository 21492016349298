.bottom-tab {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0.8rem 14px 0.5rem 14px;
  background: linear-gradient(90deg, #0f5dd2 0%, #320690 100%);
  color: #fff;
  @include grid(4, 1fr, 20px);
  z-index: 5;

  .item {
    @include flex(center, center, column);

    svg {
      font-size: 0.9rem;
    }

    P {
      font-size: clamp(0.8rem, 2vw, 0.9rem);
    }
  }
}

@include for-phone-only {
  .bottom-tab {
  }
}

@include for-tablet-potrait-up {
  .bottom-tab {
  }
}

@include for-tablet-landscape-up {
  .bottom-tab {
  }
}

@include for-desktop-up {
  .bottom-tab {
    display: none;
  }
}

@include for-big-desktop-up {
}
