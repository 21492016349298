.overview-page {
  background-color: #f5f5f5;
  padding-bottom: 100px;

  .customer-name {
    padding: 2rem 14px 1rem 14px;

    h3 {
      font-size: clamp(1rem, 3vw, 1.2rem);
      font-weight: 600;
      text-transform: capitalize;
    }
  }

  .cards {
    .mobile-arrangement {
      @include flex(center, flex-start, column);
      padding: 0 14px;
      padding-top: 1.5rem;
    }

    .tablet-arrangement,
    .desktop-arrangement {
      display: none;
    }

    // CARDS
    .card {
      width: 100%;
      border-radius: 1.25rem;
      background: #f8f9ff;
      box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
      padding: 1.25rem;
      margin-bottom: 2rem;
    }

    // balance card
    .balance-card {
      background-color: $darkBlue;
      .title-account-type {
        @include flex(flex-start, space-between, row);
        margin-bottom: 1.5rem;
        color: #fff;

        h4 {
          font-size: clamp(0.9rem, 3vw, 1rem);
        }
      }

      h2.balance {
        font-size: clamp(1.5rem, 3vw, 1.8rem);
        margin-bottom: 1.5rem;
        color: #fff;
      }

      .btns {
        @include flex(center, space-between, row);
      }
    }

    // cryptos card
    .cryptos-card {
      .title {
        @include flex(center, space-between, row);
        margin-bottom: 1.5rem;

        h4 {
          font-size: clamp(0.9rem, 3vw, 1rem);
        }
      }

      h2.balance {
        font-size: clamp(1.5rem, 3vw, 1.8rem);
        margin-bottom: 1.5rem;
      }

      .table {
        @include grid(2, 1fr, 0);

        .item {
          padding: 1rem;

          h5 {
            margin-bottom: 0.5rem;
            color: #808080;
            font-size: 0.875rem;
            font-weight: 700;
          }

          h2 {
            font-size: clamp(1.5rem, 3vw, 1.8rem);
            font-weight: 400;
          }

          &:nth-child(odd) {
            border-right: 0.06rem solid #adadad;
          }

          &:nth-child(even) {
            padding-left: 3rem;
          }

          &:nth-child(1),
          &:nth-child(2) {
            border-bottom: 0.06rem solid #adadad;
          }
        }
      }
    }

    // loan and investment card
    .loan-investment-card {
      .title {
        @include flex(center, space-between, row);
        margin-bottom: 1.5rem;

        h4 {
          font-size: clamp(0.9rem, 3vw, 1rem);
        }
      }

      h2.balance {
        font-size: clamp(1.5rem, 3vw, 1.8rem);
        margin-bottom: 0.5rem;
      }

      p.brief {
        font-size: clamp(0.75rem, 2vw, 0.85rem);
        font-weight: 300;
        margin-bottom: 1.5rem;
      }

      .loan {
        margin-bottom: 1rem;
        padding-bottom: 2rem;
        border-bottom: 0.06rem solid #adadad;
      }
    }

    // transaction history card
    .transaction-history-card {
      .title {
        @include flex(center, space-between, row);
        margin-bottom: 1.5rem;

        h4 {
          font-size: clamp(0.9rem, 3vw, 1rem);
          font-weight: 700;
        }
      }

      .sub-title {
        margin-bottom: 1rem;
        padding-bottom: 1.25rem;
        border-bottom: 0.06rem dashed #808080;
      }

      .no-transaction {
        @include flex(center, center, row);
        height: 20vh;

        h4 {
          font-size: clamp(1rem, 3vw, 1.3rem);
        }
      }

      .past-transactions {
        margin-bottom: 1.5rem;

        .item {
          @include flex(center, space-between, row);
          margin-bottom: 1.5rem;

          .type {
            @include flex(center, flex-start, row);

            img {
              margin-right: 0.5rem;
              width: 32px;
            }

            p {
              font-size: clamp(0.8rem, 2vw, 0.95rem);
              text-transform: capitalize;
            }
          }
        }
      }

      .btn {
        @include flex(center, center, row);
      }
    }

    // cards card
    .cards-card {
      position: relative;
      .title {
        @include flex(center, space-between, row);
        margin-bottom: 1.5rem;

        h4 {
          font-size: clamp(0.9rem, 3vw, 1rem);
          font-weight: 700;
        }
      }

      .container {
        .request {
          position: absolute;
          top: 1.25rem;
          right: 1.25rem;
          color: $primaryColor;
          font-size: clamp(0.9rem, 3vw, 1rem);
          font-weight: 500;
          cursor: pointer;
        }

        .credit-debit-card {
          padding: 0.6rem 0.6rem 1rem 0.6rem;
          border-radius: 0.62269rem;
          background: #320690;
          box-shadow: 0px 0px 3.985086679458618px 0px rgba(0, 0, 0, 0.1);
          color: #fff;
          @include flex(flex-start, center, column);

          .logo {
            margin-bottom: 1.5rem;
            @include flex(center, flex-start, row);
            height: 32px;
          }

          .chip {
            @include flex(center, flex-start, row);
            display: inline-flex;
            width: 2.30294rem;
            height: 2.02738rem;
            background-color: #ff9800;
            background-image: url('../../../assets/chip-print.svg');
            background-size: 100%;
            background-repeat: no-repeat;
            background-position: center;
            border-radius: 5px;
            margin-bottom: 0.5rem;
          }

          .number {
            @include grid(4, 1fr, 1rem);
            width: 100%;
            margin-bottom: 1.4rem;

            p {
              font-size: clamp(1rem, 3vw, 1.1rem);
            }
          }

          .expiration-issuer {
            @include flex(center, space-between, row);
            width: 100%;

            p {
              font-size: clamp(1rem, 3vw, 1.1rem);

              &.cvc {
                font-size: clamp(0.8rem, 2vw, 0.9rem);
              }
            }

            img {
              width: 1.76825rem;
              height: 1.18306rem;
            }
          }
        }

        .request-card {
          @include flex(center, center, row);
          min-height: 10rem;
          position: relative;
          a {
            color: $primaryColor;
            font-size: clamp(0.9rem, 3vw, 1rem);
            font-weight: 500;
            cursor: pointer;
            position: absolute;
            top: -25%;
            right: 0;
          }

          .credit-debit-card {
            width: 100%;
            max-width: 350px;
          }
        }

        .slide {
          margin-bottom: 2rem;
        }
      }
    }
  }

  .card-loader {
    @include flex(center, center, row);
    height: 30vh;

    img {
      width: 2rem;
      height: 2rem;
    }
  }
}

@include for-phone-only {
  .overview-page {
    .cards {
      .mobile-arrangement {
        padding: 0 24px;
      }
    }
  }
}

@include for-tablet-potrait-up {
  .overview-page {
    .cards {
      height: auto;

      .mobile-arrangement,
      .desktop-arrangement {
        display: none;
      }

      .tablet-arrangement {
        @include flex(center, flex-start, column);
        padding: 0 40px;
        margin-top: 2rem;

        .row {
          @include flex(center, space-between, row);
          width: 100%;
          margin-bottom: 1.5rem;

          .card {
            width: 45%;

            &.cards-card {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

@include for-tablet-landscape-up {
  .overview-page {
    .cards {
      .tablet-arrangement {
        padding: 0 60px;
      }
    }
  }
}

@include for-desktop-up {
  .overview-page {
    .customer-name {
      display: none;
    }

    .cards {
      margin-top: 2rem;

      .tablet-arrangement,
      .mobile-arrangement {
        display: none;
      }

      .desktop-arrangement {
        @include flex(center, flex-start, column);
        padding: 0 100px;

        .row {
          @include flex(flex-start, space-between, row);
          width: 100%;
          margin-bottom: 2rem;

          .mini-row {
            width: 67%;
            @include flex(flex-start, flex-start, column);
          }
        }
      }

      // CARD
      .card {
        margin-bottom: 0;
      }

      // balance card
      .balance-card {
        width: 45%;
        margin-right: 1.5rem;
      }

      // cryptos card
      .cryptos-card {
        margin-bottom: 1.5rem;
      }

      // loan and investment card
      .loan-investment-card {
        @include flex(flex-start, space-between, row);

        .loan {
          padding-bottom: 0;
          border: none;
          border-right: 0.06rem solid #adadad;
          width: 50%;
          margin-right: 1rem;
        }

        .investment {
          width: 50%;
          padding-left: 2rem;
        }
      }

      // transaction history card
      .transaction-history-card {
        width: 30%;
        margin-right: 1.5rem;
      }

      // cards card
      .cards-card {
        margin-bottom: 1.5rem;
      }
    }
  }
}

@include for-big-desktop-up {
}
