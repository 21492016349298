.loans-page {
  background: #f5f5f5;
  overflow-x: hidden;

  // LOAN OPTIONS
  .loan-options {
    margin-bottom: 2rem;
    padding: 4rem 14px;

    h2 {
      text-align: center;
      font-size: clamp(1rem, 3vw, 1.5rem);
      margin-bottom: 2rem;
      font-weight: 600;
    }

    .cards {
      @include grid(2, 1fr, 0.7rem);
      .card {
        border-radius: 0.625rem 0.625rem 0rem 0rem;
        margin-bottom: 1.5rem;
        .img {
          @include flex(center, center, row);
        }

        .text {
          padding: 1rem 0;
          @include flex(center, center, row);
          background-color: $darkBlue;
          border-radius: 0rem 0rem 0.625rem 0.625rem;

          h4 {
            font-size: clamp(0.9rem, 3vw, 1rem);
            color: #fff;
          }
        }
      }
    }
  }
}

@include for-phone-only {
  .loans-page {
    // LOAN OPTIONS
    .loan-options {
      padding: 4rem 24px;
    }
  }
}

@include for-tablet-potrait-up {
  .loans-page {
    // LOAN OPTIONS
    .loan-options {
      padding: 4rem 40px;

      .cards {
        @include grid(3, 1fr, 1rem);
      }
    }
  }
}

@include for-tablet-landscape-up {
  .loans-page {
    // LOAN OPTIONS
    .loan-options {
      padding: 4rem 60px;

      .cards {
        @include grid(3, 1fr, 2rem);
      }
    }
  }
}

@include for-desktop-up {
  .loans-page {
    // LOAN OPTIONS
    .loan-options {
      padding: 4rem 100px;
    }
  }
}

@include for-big-desktop-up {
}
