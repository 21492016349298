.account-settings-page {
  background-color: #f5f5f5;
  min-height: 100vh;
  padding-bottom: 100px;

  .page-title {
    padding: 1.5rem 14px 1rem 14px;

    h3 {
      font-size: clamp(1rem, 3vw, 1.5rem);
      font-weight: 600;
      text-transform: capitalize;
    }
  }

  .block {
    padding: 0 14px;
    margin-top: 0.5rem;

    h4.block-title {
      margin-bottom: 1.25rem;
      font-size: clamp(1.1rem, 3vw, 1.3rem);
      font-weight: 500;
    }

    .card {
      border-radius: 0.625rem;
      background: #fff;
      padding: 1.25rem;
      margin-bottom: 2.5rem;

      .row {
        @include flex(center, space-between, row);
        margin-bottom: 2.5rem;

        p {
          font-size: clamp(0.9rem, 2vw, 1rem);
          font-weight: 500;

          &:last-child {
            color: #808080;
            font-weight: 400;
          }

          &.name {
            text-transform: capitalize;
          }
        }
      }
    }

    .logout {
      @include flex(center, flex-start, row);
      display: inline-flex;
      cursor: pointer;
      color: $errorColor;

      img {
        width: 1.25rem;
        height: 1.25rem;
        margin-right: 0.5rem;
      }
    }

    &.security {
      .card {
        margin-bottom: 1.5rem;

        p {
          display: inline;

          &:hover {
            cursor: pointer;
            color: $primaryColor;
          }
        }
      }
    }
  }
}

@include for-phone-only {
  .account-settings-page {
    .page-title {
      padding: 1.5rem 24px 1rem 24px;
    }

    .block {
      padding: 0 24px;
    }
  }
}

@include for-tablet-potrait-up {
  .account-settings-page {
    .page-title {
      padding: 1.5rem 40px 1rem 40px;
    }

    .block {
      padding: 0 40px;
    }
  }
}

@include for-tablet-landscape-up {
  .account-settings-page {
    .page-title {
      padding: 1.5rem 60px 1rem 60px;
    }

    .block {
      padding: 0 60px;
    }
  }
}

@include for-desktop-up {
  .account-settings-page {
    .page-title {
      padding: 2rem 100px;
    }

    .block {
      padding: 0 100px;
    }
  }
}

@include for-big-desktop-up {}