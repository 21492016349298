.dashboard-navbar {
  .desktop {
    display: none;
  }

  .mobile {
    padding: 1.5rem 14px 1rem 14px;
    background: linear-gradient(90deg, #0f5dd2 0%, #320690 100%);
    @include flex(flex-start, space-between, row);

    .logo {
      @include flex(flex-start, flex-start, row);
      color: #fff;

      a {
        width: 150px;
        @include flex(center, center, row);
      }
    }

    .hamburger-notification {
      @include flex(center, flex-start, row);

      .notif {
        width: 1.875rem;
        height: 1.875rem;
        @include flex(center, center, row);
        margin-right: 1.5rem;
        position: relative;

        p {
          color: #fff;
          position: absolute;
          right: -20%;
          top: -40%;
          background-color: $errorColor;
          border-radius: 50%;
          font-size: 0.7rem;
          width: 24px;
          height: 24px;
          @include flex(center, center, row);
        }
      }
    }

    .mob-nav {
      position: fixed;
      top: 0;
      right: 0;
      width: 100%;
      height: 100vh;
      @include flex(center, space-between, row);
      transform: translateX(100%);
      transition: 0.4s linear all;

      &.show {
        z-index: 10;
        transform: translateX(0);
        transition: 0.4s linear all;
      }

      .void {
        width: 30%;
        height: 100%;
        background: rgba(0, 0, 0, 0.4);
      }

      .items-container {
        width: 70%;
        height: 100%;
        background-color: #fff;
        @include flex(space-between, flex-start, column);
        overflow-y: scroll;

        .name-account-profile {
          padding: 1.25rem;
          background-color: $primaryColor;
          @include flex(center, flex-start, row);
          color: #fff;
          margin-bottom: 2rem;

          .profile {
            width: 2.5rem;
            height: 2.5rem;
            border-radius: 50%;
            background-color: rgba(217, 217, 217, 0.4);
            font-size: 1.2rem;
            padding: 0.3rem;
            @include flex(center, center, row);
            margin-right: 1rem;
          }

          .info {
            h3 {
              font-size: clamp(1.1rem, 3vw, 1.3rem);
              text-transform: capitalize;
              margin-bottom: 0.3rem;
            }

            p {
              font-size: clamp(0.8rem, 2vw, 0.9rem);
            }
          }
        }

        .list {
          li {
            @include flex(center, flex-start, row);
            font-size: clamp(1rem, 3vw, 1.3rem);
            padding: 1rem 1.5rem;
            border-bottom: 0.03rem solid #808080;

            svg {
              margin-right: 1rem;
            }

            &.active-page {
              color: $primaryColor;
            }
          }
        }

        .sign-out {
          @include flex(center, flex-start, row);
          font-size: clamp(1rem, 3vw, 1.3rem);
          padding: 1rem 1.5rem;
          color: $errorColor;

          svg {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}

@include for-phone-only {
  .dashboard-navbar {
    .mobile {
      padding: 1.5rem 24px 0.5rem 24px;
    }
  }
}

@include for-tablet-potrait-up {
  .dashboard-navbar {
    .desktop {
      display: none;
    }

    .mobile {
      padding: 1.5rem 40px 0.5rem 40px;

      .mob-nav {
        .void {
          width: 40%;
        }

        .items-container {
          width: 60%;

          .name-account-profile {
            padding: 2rem 1.25rem;
          }

          .list {
            li {
              padding: 1.5rem 1.5rem;

              svg {
                margin-right: 1.5rem;
              }
            }
          }
        }
      }
    }
  }
}

@include for-tablet-landscape-up {
  .dashboard-navbar {
    .desktop {
      display: none;
    }

    .mobile {
      padding: 1.5rem 60px 0.5rem 60px;
    }
  }
}

@include for-desktop-up {
  .dashboard-navbar {
    .desktop {
      padding: 2.5rem 100px;
      display: block;
      background-color: $darkBlue;
      background-image: url('../../../assets/wave-particles.png');
      background-size: 70%;
      background-repeat: no-repeat;
      background-position: right top;
      color: #fff;

      .top {
        @include flex(center, space-between, row);
        width: 100%;
        margin-bottom: 2rem;

        .profile-notif-settings {
          @include flex(center, flex-start, row);

          .item {
            margin-right: 1.25rem;
            @include flex(center, center, row);

            &.profile,
            &.logout {
              width: 3.5rem;
              height: 3.5rem;
              border-radius: 50%;
              background-color: rgba(217, 217, 217, 0.4);
              font-size: 1.5rem;
              padding: 0.3rem;
            }

            &.notif {
              position: relative;
              p {
                color: #fff;
                position: absolute;
                right: -5%;
                top: -10%;
                background-color: $errorColor;
                border-radius: 50%;
                font-size: 0.7rem;
                width: 24px;
                height: 24px;
                @include flex(center, center, row);
              }
            }
          }
        }

        .logo {
          @include flex(center, center, row);
          width: 200px;
        }
      }

      .name {
        margin-bottom: 2.5rem;

        h2 {
          font-size: clamp(1.3rem, 3vw, 1.5rem);
          text-transform: capitalize;
        }
      }

      .nav-pages {
        @include flex(center, flex-start, row);
        border-bottom: 0.0625rem solid #255dff;
        width: 60%;

        li {
          margin-right: 3rem;
          color: #7297ff;
          padding-bottom: 0.5rem;
          cursor: pointer;

          &.active {
            color: #fff;
            border-bottom: 0.125rem solid #fff;
            padding: 0 2rem 0.5rem 2rem;
          }
        }
      }
    }

    .mobile {
      display: none;
    }
  }
}

@include for-big-desktop-up {
}
