.savings-investments-page {
  background: #f5f5f5;
  overflow-x: hidden;

  h2.title {
    text-align: center;
    font-size: clamp(1.1rem, 3vw, 1.5rem);
    margin-bottom: 1rem;
    font-weight: 600;
  }

  p.sub-title {
    font-size: clamp(0.9rem, 3vw, 1rem);
    line-height: 28px;
    margin-bottom: 2rem;
    text-align: center;
  }

  // GROW YOUR SAVINGS
  .grow-savings {
    padding: 4rem 14px 2rem 14px;

    .cards {
      @include grid(1, 1fr, 0.7rem);
      .card {
        border-radius: 0.625rem 0.625rem 0rem 0rem;
        margin-bottom: 1.5rem;
        .img {
          @include flex(center, center, row);
        }

        .text {
          padding: 1rem 0;
          @include flex(center, center, row);
          background-color: $darkBlue;
          border-radius: 0rem 0rem 0.625rem 0.625rem;

          h4 {
            font-size: clamp(0.9rem, 3vw, 1rem);
            color: #fff;
          }
        }
      }
    }
  }

  // TOP TIER INVESTMENTS
  .top-tier-investments {
    margin-bottom: 2rem;
    padding: 2rem 0;

    p.sub-title {
      padding: 0 14px;
    }

    .cards {
      .card {
        border-radius: 1.25rem;
        background: #fff;
        padding: 1.25rem;
        margin-bottom: 2.5rem;

        .img {
          margin-bottom: 1.25rem;
        }

        .text {
          h3 {
            font-size: clamp(1.2rem, 3vw, 1.4rem);
            margin-bottom: 0.5rem;
          }

          p {
            margin-bottom: 1rem;
          }
        }
      }
    }
  }
}

@include for-phone-only {
  .savings-investments-page {
    // GROW YOUR SAVINGS
    .grow-savings {
      padding: 4rem 24px;
    }

    // TOP TIER INVESTMENTS
    .top-tier-investments {
      p.sub-title {
        padding: 0 24px;
      }
    }
  }
}

@include for-tablet-potrait-up {
  .savings-investments-page {
    // GROW YOUR SAVINGS
    .grow-savings {
      padding: 4rem 40px;

      .cards {
        @include grid(2, 1fr, 1rem);
      }
    }

    // TOP TIER INVESTMENTS
    .top-tier-investments {
      padding: 4rem 40px;

      p.sub-title {
        padding: 0;
      }

      .cards {
        .card {
          @include flex(center, space-between, row-reverse);
          padding: 3rem 1.25rem;

          .img {
            margin-bottom: 0;
            margin-left: 1rem;
          }
        }
      }
    }
  }
}

@include for-tablet-landscape-up {
  .savings-investments-page {
    // GROW YOUR SAVINGS
    .grow-savings {
      padding: 4rem 60px;
    }

    // TOP TIER INVESTMENTS
    .top-tier-investments {
      padding: 4rem 60px;
    }
  }
}

@include for-desktop-up {
  .savings-investments-page {
    .page-header {
      .text-info {
        padding: 4rem 100px;
      }
    }

    p.sub-title {
      width: 80%;
      margin: 0 auto 2rem auto;
    }

    // GROW YOUR SAVINGS
    .grow-savings {
      padding: 4rem 100px;

      .cards {
        @include grid(3, 1fr, 0.7rem);
      }
    }

    // TOP TIER INVESTMENTS
    .top-tier-investments {
      padding: 4rem 100px;

      .cards {
        .card {
          padding: 3rem 2.25rem;
        }
      }
    }
  }
}

@include for-big-desktop-up {
}
