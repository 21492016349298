.home-page {
  background: #f5f5f5;
  overflow-x: hidden;

  h2.title {
    text-align: center;
    font-size: clamp(1.2rem, 3vw, 1.5rem);
    margin-bottom: 1rem;
    font-weight: 600;
  }

  p.sub-title {
    line-height: 28px;
    margin-bottom: 2rem;
    text-align: center;
    color: #5e5e5e;
  }

  // HEADER
  .header {
    padding: 2rem 14px 0 14px;
    background-color: #fbfcff;

    .info {
      margin-bottom: 2.5rem;

      h1 {
        font-size: clamp(1.8rem, 5vw, 3rem);
        font-weight: 700;
        margin-bottom: 0.5rem;
      }

      p {
        line-height: 26px;
        width: 75%;
        margin-bottom: 1rem;
      }
    }

    .img {
      @include flex(center, center, row);
    }
  }

  // STATS
  .stats {
    color: #fff;
    padding: 2rem 14px;
    background-color: $darkBlue;
    position: relative;
    overflow: hidden;

    .stat {
      padding: 0.5rem 0;
      margin-bottom: 0.5rem;
      position: relative;
      z-index: 2;

      &:nth-child(2) {
        border-top: 1px solid #fff;
        border-bottom: 1px solid #fff;
        padding: 0.8rem 0;
      }

      span {
        font-weight: 300;
      }
    }

    img {
      position: absolute;
      right: -40%;
      top: 0;
      width: 19.03263rem;
      height: 19.03263rem;
      transform: rotate(-18.162deg);
      flex-shrink: 0;
    }
  }

  // BANKING OFFERS
  .banking-offers {
    padding: 4rem 14px 4rem 14px;

    .container {
      background: #fff;
      padding: 1.25rem 14px;

      .offer {
        margin-bottom: 2.5rem;
        .img {
          padding: 2rem;
          width: 14.75rem;
          margin: 0 auto;
          border-radius: 0.625rem;
          background: #f4f6ff;
          margin-bottom: 1.25rem;
        }

        h3 {
          font-size: clamp(1.3rem, 3vw, 1.5rem);
          margin-bottom: 0.5rem;
        }

        p {
          margin-bottom: 1.25rem;
        }
      }
    }
  }

  // THE RIGHT ACCOUNT
  .the-right-account {
    padding: 4rem 14px 2rem 14px;
    background-color: $darkBlue;
    height: 480px;
    margin-bottom: 15rem;

    h2.title,
    p.sub-title {
      color: #fff;
    }

    .cards {
      overflow: scroll;
      position: relative;
      height: 420px;

      .cont {
        width: 1100px;
        @include flex(flex-start, flex-start, row);
        position: relative;
        position: absolute;
        .card {
          border-radius: 1.25rem;
          background: #fcfcff;
          box-shadow: 0px 0px 9px 1px rgba(0, 0, 0, 0.1);
          padding: 1.8rem 14px;
          width: 17rem;
          margin-right: 1rem;
          height: 400px;
          @include flex(center, flex-start, column);
          text-align: center;

          .img {
            margin-bottom: 1rem;
          }

          h3 {
            margin-bottom: 0.5rem;
          }

          p {
            color: #5e5e5e;
            line-height: 30px;
          }

          button {
            position: absolute;
            bottom: 1.1rem;
          }
        }
      }
    }
  }

  // OUR TOP PRIORITY
  .top-priority {
    padding: 4rem 14px 2rem 14px;

    .cards {
      @include flex(center, center, column);
      .card {
        border-radius: 1.25rem;
        background: #ecefff;
        padding: 1.8rem 14px;
        margin-bottom: 2.5rem;
        @include flex(center, center, column);

        .img {
          margin-bottom: 1rem;
        }

        h3 {
          @include flex(flex-start, center, column);
          margin-bottom: 1.5rem;
        }

        p {
          color: #5e5e5e;
          line-height: 30px;
        }
      }
    }
  }

  // CUSTOMER TESTIMONILAS
  .customer-testimonails {
    padding: 4rem 0 3rem 0;

    .cards {
      overflow: scroll;

      .cont {
        width: 850px;
        @include flex(flex-start, flex-start, row);
        padding-left: 14px;

        .card {
          border-radius: 1.25rem;
          background: #ecefff;
          padding: 1.8rem 14px;
          width: 16rem;
          margin-right: 1.5rem;

          .customer {
            @include flex(center, flex-start, row);
            margin-bottom: 1rem;

            .img {
              width: 6rem;
              height: 6rem;
              margin-right: 1rem;
              border-radius: 5px;
              border: 5px solid #fff;

              img {
                border-radius: 5px;
              }
            }

            h4 {
              @include flex(flex-start, center, column);

              span {
                &:first-child {
                  margin-bottom: 0.2rem;
                }
                &:last-child {
                  font-weight: 400;
                }
              }
            }
          }

          h3 {
            margin-bottom: 0.5rem;
          }

          p {
            color: #5e5e5e;
            line-height: 30px;
          }
        }
      }
    }
  }
}

@include for-phone-only {
  .home-page {
    // HEADER
    .header {
      padding: 2rem 24px 0 24px;
    }

    // STATS
    .stats {
      padding: 2rem 24px;

      img {
        right: -20%;
      }
    }

    // BANKING OFFERS
    .banking-offers {
      padding: 4rem 24px 4rem 24px;

      .container {
        padding: 1.25rem 1rem;
      }
    }

    // THE RIGHT ACCOUNT
    .the-right-account {
      padding: 4rem 24px 2rem 24px;
    }

    // OUR TOP PRIORITY
    .top-priority {
      padding: 4rem 24px 2rem 24px;
    }

    // CUSTOMER TESTIMONILAS
    .customer-testimonails {
      .cards {
        .cont {
          width: 1000px;
          padding-left: 24px;

          .card {
            width: 20rem;
          }
        }
      }
    }
  }
}

@include for-tablet-potrait-up {
  .home-page {
    // HEADER
    .header {
      padding: 2rem 40px 0 40px;
    }

    // STATS
    .stats {
      padding: 2rem 40px;
      @include flex(center, space-between, row);

      .stat {
        &:nth-child(2) {
          padding: 0.8rem 3rem;
          border: none;
          border-right: 1px solid #fff;
          border-left: 1px solid #fff;
        }
      }

      img {
        right: -10%;
      }
    }

    // BANKING OFFERS
    .banking-offers {
      padding: 4rem 40px 4rem 40px;

      .container {
        background: #fff;

        .offer {
          @include flex(center, space-between, row);
          padding: 1rem 24px;

          &.reverse {
            @include flex(center, space-between, row-reverse);
          }

          .info {
            width: 50%;
          }
        }
      }
    }

    // THE RIGHT ACCOUNT
    .the-right-account {
      padding: 4rem 40px 2rem 40px;
      height: 430px;

      .cards {
        height: 430px;

        .cont {
          width: 1400px;
          .card {
            width: 19rem;
            height: 410px;
          }
        }
      }
    }

    // OUR TOP PRIORITY
    .top-priority {
      padding: 4rem 40px 2rem 40px;

      .cards {
        @include grid(2, 1fr, 20px);
        .card {
          &:last-child {
            grid-column: 1/3;
            margin: 0 auto;
            width: 25rem;
          }
        }
      }
    }

    // CUSTOMER TESTIMONILAS
    .customer-testimonails {
      .cards {
        .cont {
          width: 1300px;
          padding-left: 40px;

          .card {
            width: 23rem;
            height: 400px;
          }
        }
      }
    }
  }
}

@include for-tablet-landscape-up {
  .home-page {
    // HEADER
    .header {
      padding: 2rem 60px 0 60px;
      @include flex(center, space-between, row);

      .info {
        width: 50%;
      }

      .img {
        width: 50%;
      }
    }

    // STATS
    .stats {
      padding: 2rem 150px;

      .stat {
        &:nth-child(2) {
          padding: 0.8rem 5rem;
        }
      }
    }

    // BANKING OFFERS
    .banking-offers {
      padding: 4rem 60px 4rem 60px;
    }

    // THE RIGHT ACCOUNT
    .the-right-account {
      padding: 4rem 60px 2rem 60px;
      height: 400px;
    }

    // OUR TOP PRIORITY
    .top-priority {
      padding: 4rem 60px 2rem 60px;
    }

    // CUSTOMER TESTIMONILAS
    .customer-testimonails {
      .cards {
        .cont {
          width: 1400px;
          padding-left: 60px;

          .card {
            width: 25rem;
          }
        }
      }
    }
  }
}

@include for-desktop-up {
  .home-page {
    p.sub-title {
      width: 80%;
      margin: 0 auto 2rem auto;
    }

    // HEADER
    .header {
      padding: 2rem 100px 0 100px;
    }

    // STATS
    .stats {
      padding: 2rem 200px;

      img {
        right: -5%;
      }
    }

    // BANKING OFFERS
    .banking-offers {
      padding: 4rem 100px;
    }

    // THE RIGHT ACCOUNT
    .the-right-account {
      padding: 4rem 100px 2rem 100px;

      .cards {
        overflow: auto;
        height: 450px;
        .cont {
          width: auto;
          @include grid(4, 1fr, 1rem);

          .card {
            width: auto;
            margin-right: 0;
            height: 430px;
          }
        }
      }
    }

    // OUR TOP PRIORITY
    .top-priority {
      padding: 4rem 100px 2rem 100px;

      .cards {
        @include grid(3, 1fr, 20px);
        align-items: flex-start;
        .card {
          height: 500px;
          &:last-child {
            grid-column: auto;
            margin: 0;
            width: auto;
          }
        }
      }
    }

    // CUSTOMER TESTIMONILAS
    .customer-testimonails {
      .cards {
        .cont {
          width: 100%;
          padding: 0 100px;
          @include grid(3, 1fr, 20px);
          .card {
            height: 500px;
            margin-right: 0;
            width: auto;
          }
        }
      }
    }
  }
}

@include for-big-desktop-up {
}
