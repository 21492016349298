.cards-page {
  background-color: #f5f5f5;
  min-height: 100vh;
  padding-bottom: 100px;

  .page-title {
    padding: 1.5rem 14px 1rem 14px;
    @include flex(center, flex-start, row);

    svg {
      margin-right: 0.5rem;
      cursor: pointer;

      &:hover {
        color: $primaryColor;
      }
    }

    h3 {
      font-size: clamp(1rem, 3vw, 1.5rem);
      font-weight: 600;
      text-transform: capitalize;
    }
  }

  .card-types {
    margin-top: 0.5rem;
    padding: 0 14px;
    @include flex(flex-start, center, column);

    .card {
      border-radius: 1rem;
      background: #fff;
      padding: 1rem 1.25rem;
      margin-bottom: 1.25rem;
      width: 100%;

      h3 {
        font-size: clamp(1rem, 3vw, 1.1rem);
        font-weight: 500;
      }
    }
  }

  .request-card {
    height: 30vh;
    @include flex(center, center, row);

    h2 {
      font-size: clamp(1rem, 3vw, 1.1rem);
      color: $primaryColor;
      cursor: pointer;
    }

    img {
      width: 24px;
      height: 24px;
      margin-left: 0.5rem;
    }
  }
}

@include for-phone-only {
  .cards-page {
    .page-title {
      padding: 1.5rem 24px 1rem 24px;
    }

    .card-types {
      padding: 0 24px;
    }
  }
}

@include for-tablet-potrait-up {
  .cards-page {
    .page-title {
      padding: 1.5rem 40px 1rem 40px;
    }

    .card-types {
      padding: 0 40px;
    }
  }
}

@include for-tablet-landscape-up {
  .cards-page {
    .page-title {
      padding: 1.5rem 60px 1rem 60px;
    }

    .card-types {
      padding: 0 60px;
    }
  }
}

@include for-desktop-up {
  .cards-page {
    padding-bottom: 100px;

    .page-title {
      padding: 2rem 100px;
    }

    .card-types {
      padding: 0 100px;
    }
  }
}

@include for-big-desktop-up {
}
