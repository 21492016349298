.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  scrollbar-width: none;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 200;
  @include flex(flex-start, center, row);

  &::-webkit-scrollbar {
    display: none;
  }

  .modal {
    width: 90%;
    background-color: #fff;
    border-radius: 10px;
    padding: 2rem 14px;
    margin-top: 4rem;

    .close-btn {
      @include flex(center, flex-end, row);
      margin-bottom: 1.5rem;

      svg {
        cursor: pointer;
        color: grey;

        &:hover {
          color: $errorColor;
        }
      }
    }
  }

  //   DEPOSIT MODAL
  .deposit-modal {
    .drop-zone {
      margin-bottom: 1.5rem;
    }

    .preview {
      margin-bottom: 1.5rem;
    }

    .btn {
      @include flex(center, center, row);
      margin-top: 1.5rem;

      button {
        padding-top: 0.6rem;
        padding-bottom: 0.6rem;
      }
    }
  }

  // STATUS MODAL
  .status-modal {
    @include flex(center, center, column);

    .icon {
      margin-bottom: 2rem;
    }

    p {
      font-size: clamp(0.9rem, 3vw, 1rem);
      margin-bottom: 1.5rem;
      text-align: center;
    }
  }
}

.modal-container#status {
  @include flex(center, center, row);
}

// SECURE AUTHORIZATION CODE MODAL
.secure-authorization-modal {
  .MuiBox-root {
    padding: 32px 14px;
    height: 80vh;
    overflow-y: scroll;
    border: none;
    outline: none;

    .close-btn {
      @include flex(center, flex-end, row);
      margin-bottom: 1rem;
      color: $errorColor;
      font-size: 1.5rem;
    }

    h2 {
      font-size: clamp(1rem, 3vw, 1.5rem);
      text-align: center;
      margin-bottom: 1rem;
    }

    p.warning {
      font-size: clamp(0.9rem, 3vw, 1rem);
      line-height: 32px;
      color: $errorColor;
      margin-bottom: 1rem;
    }

    .form-control {
      label {
        margin-bottom: 1rem;
      }
    }

    p.disclaimer {
      font-size: clamp(0.8rem, 2vw, 0.9rem);
      margin-bottom: 1rem;
    }

    p.error {
      font-size: clamp(0.9rem, 3vw, 1rem);
      color: $errorColor;
      text-align: center;
      margin-bottom: 1rem;
    }

    .btn {
      @include flex(center, center, row);
    }
  }
}

.mantine-Modal-content {
  .mantine-Modal-title {
    font-size: clamp(1.2rem, 3vw, 1.5rem);
    font-weight: 600;
    margin-bottom: 1rem;
  }

  .btn {
    @include flex(center, center, row);
    margin-top: 1rem;
  }

  .mantine-Modal-close {
    @include flex(center, center, row);

    svg {
      margin: 0;
    }
  }
}

.notification-modal {
  .mantine-Modal-title {
    font-size: clamp(1.2rem, 3vw, 1.5rem);
    font-weight: 600;
  }

  p {
    line-height: 28px;
    font-size: clamp(0.9rem, 3vw, 1rem);
  }
}

.update-info-modal {
  position: relative;
  .phone-input {
    .input {
      width: 100%;
      font-family: 'Lato', sans-serif;
      background-color: #f4f4f4;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      border: none;
      border-radius: 0.3125rem;
      border-bottom: 1px solid #8e8e8e;
      font-size: 16px;
    }

    .btn {
      border: none;
      border-bottom: 1px solid #8e8e8e;
      margin: 0;
    }
  }

  .react-tel-input .country-list {
    left: 0;
  }
}

@include for-phone-only {
}

@include for-tablet-potrait-up {
}

@include for-tablet-landscape-up {
}

@include for-desktop-up {
  .modal-container {
    .modal {
      width: 30%;
      background-color: #fff;
      border-radius: 10px;
      padding: 2rem 14px;

      .close-btn {
        svg {
          font-size: 1.5rem;
        }
      }
    }
  }

  // SECURE AUTHORIZATION CODE MODAL
  .secure-authorization-modal {
    .MuiBox-root {
      padding: 32px;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}

@include for-big-desktop-up {
}
