.forgot-password-page {
  min-height: 100vh;
  background: #ecefff;

  .auth-navbar {
    background: #fefefe;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(20px);
    padding: 1rem 14px;
    margin-bottom: 2rem;

    .logo {
      @include flex(center, center, row);
      width: 150px;
    }
  }

  .form-container {
    min-height: 60vh;
    padding: 3rem 14px;

    form {
      width: 100%;
      border-radius: 20px;
      background: #fff;
      border-radius: 5px;
      padding: 2rem 14px;
      text-align: center;
      @include flex(center, center, column);
      margin-bottom: 2rem;

      .form-control {
        width: 100%;
        margin-bottom: 2.5rem;
      }

      h2 {
        font-size: clamp(1.1rem, 3vw, 1.3rem);
        margin-bottom: 1rem;
      }

      p {
        font-size: clamp(0.9rem, 3vw, 1rem);
        color: #808080;
        margin-bottom: 2rem;
      }

      button {
        width: 80%;
      }
    }

    p.second-thoughts {
      margin-bottom: 1rem;
      font-size: clamp(0.9rem, 3vw, 1rem);
    }
  }

  .check-email {
    @include flex(center, flex-start, column);
    text-align: center;
    padding: 1rem 14px;
    padding-top: 100px;

    .img {
      width: 60%;
      margin-bottom: 1.5rem;
    }

    h4 {
      margin-bottom: 1rem;
      font-size: clamp(1.1rem, 3vw, 1.5rem);
    }

    p {
      font-size: clamp(0.9rem, 3vw, 1.1rem);
    }

    .btn {
      @include flex(center, center, row);
      margin-top: 1.5rem;
    }
  }
}

@include for-phone-only {
  .forgot-password-page {
    .auth-navbar {
      padding: 1rem 24px;
    }

    .form-container {
      padding: 3rem 24px;

      form {
        padding: 2rem 24px;
      }
    }

    .check-email {
      padding: 1rem 24px;
    }
  }
}

@include for-tablet-potrait-up {
  .forgot-password-page {
    .auth-navbar {
      padding: 1rem 40px;
    }

    .form-container {
      padding: 6rem 40px;

      form {
        .form-control {
          width: 80%;
        }

        button {
          width: 50%;
        }
      }
    }

    .check-email {
      padding: 1rem 40px;
      margin-top: 10rem;
    }
  }
}

@include for-tablet-landscape-up {
  .forgot-password-page {
    .auth-navbar {
      padding: 1rem 60px;
    }

    .form-container {
      padding: 6rem 60px;
      width: 70%;
      margin: 0 auto;
    }

    .check-email {
      padding: 1rem 60px;
    }
  }
}

@include for-desktop-up {
  .forgot-password-page {
    .auth-navbar {
      padding: 1rem 100px;
      margin-bottom: 5rem;
      @include flex(center, space-between, row);
    }

    .form-container {
      padding: 4rem 80px;
      width: 50%;
      margin: 0 auto;
    }

    .check-email {
      padding: 1rem 100px;
      margin-top: 0;

      .img {
        width: 30%;
      }
    }
  }
}

@include for-big-desktop-up {
}
